function ListItem(props) {
    // Figure out the undefined bug
    //const url = props.url.split("/")[2];
    const url = "Link"

    return (
      <h1 className="text-xl font-mono">
        {props.number + "  "}
        {props.title}
        <a className="text-indigo-600 text-base" href={props.url}>
          {url}
        </a>
      </h1>
    );
}

export default ListItem;