import React, { useState, useEffect } from "react";
import axios from "axios";
import ListItem from "./components/ListItem.js";

function App() {
  const [maindata, setmaindata] = useState([]);
  const [numbers, setnumbers] = useState([]);
  const [seen, setseen] = useState(31);
  const [renderbutton, setrenderbutton] = useState(true);
  
  useEffect(() => {
    axios
      .get("https://hacker-news.firebaseio.com/v0/topstories.json?print=pretty")
      .then((repo) => {
        setnumbers(repo.data);
        for (let x = 0; x < 30; x++) {
          axios
            .get(
              "https://hacker-news.firebaseio.com/v0/item/" +
                repo.data[x] +
                ".json?print=pretty"
            )
            .then((titledata) => {
              if (titledata.data["title"] === undefined || titledata.data["url"] === undefined) {
                setmaindata((maindata) => [...maindata, ["filler"], ["https://www.filler.com"]]);
              } else {
                setmaindata((maindata) => [...maindata, [titledata.data["title"], titledata.data["url"]]]);
              }
            });
        }
      });
  }, []);

  function AddMore() {
    for (let x = seen; x < seen + 30; x++) {
      if (x > 499) {
        setrenderbutton(false);
        break
      }
      else {
        axios
          .get(
            "https://hacker-news.firebaseio.com/v0/item/" +
              numbers[x] +
              ".json?print=pretty"
          )
          .then((titledata) => {
            setseen(seen + 30);
            if (titledata.data["title"] === undefined || null) {
              setmaindata((maindata) => [...maindata, ["filler"]]);
            } else {
              setmaindata((maindata) => [
                ...maindata,
                [titledata.data["title"], titledata.data["url"]],
              ]);
            }
          });
      }
    }
  }
    return (
      <div>
        {maindata.map((element, x) => {
          // The 7 * Math.random() was the best I could do for now and the 7 doesn't mean anything
          return (
            <ListItem
              key={7 * Math.random()}
              number={x + 1}
              title={element[0]}
              url={element[1]}
            />
          );
        })}
        {renderbutton === true ? (
          <button
            className="md:w-16 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-2 px-3 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300"
            onClick={AddMore}>More</button>
        ) : (
          <h2 className="text-xl">You Haved Reached the End</h2>
        )}
      </div>
    );
}

export default App;
